//setup the internationalization for languages
import Vue from "vue";
import VueI18n from "vue-i18n";

//use the imported library
Vue.use(VueI18n);

//the language setup
const messages = {
    'en': {
        loginHeadingMsg: "Please login to continue",
        registerHeadingMsg: "Create your account",
        registerHeadingSuccessMsg: "Success!",
        forgotPasswordLinkText: "Forgot Password?",
        backToLoginLinkText: "Return to log in",
        resetPasswordHeadingMsg: "Reset Password",
        resetPasswordHeadingDescription: "Enter your email and we’ll send you a link to reset your password.",
        createAccountLinkText: "Create Account",
        loginLinkText: "Already have a Craydel account? Log in",
        privacyPolicyLinkText: "Privacy Policy",
        userNoticeLinkText: "Terms of Use",
        loginWithGoogleLinkText: "Login with Google",
        loginWithLinkedinLinkText: "Login with LinkedIn",
        usernameFieldText: "Enter your Email",
        loginStepOneButtonText: "Continue",
        fullNameLabelText: "Enter full name",
        emailAddressLabelText: "Enter email address",
        phoneNumberLabelText: "Enter phone number",
        passwordLabelText: "Enter Password",
        confirmPasswordLabelText: "Confirm Password",
        verifyAccountHeading: "Email Verified!",
        verifyAccountErrorHeading: "Oops! Email Verification Failed",
        verifyAccountText: "Your email address has been successfully verified. Please login to access your account.",
        verifyAccountErrorText: "Sorry, your email address could not be verified. Please check the email you have used, or contact support!",
        registrationSuccessText: "Your account have been created! Please check your email for the activation link.",
        updatePassHeading: "Update your password",
        updatePassText: "Please choose a password to proceed."
    },
    'es': {}
};

//configuring the plugin
const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'es',
    messages,
});


//exports
export default i18n;

