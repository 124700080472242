<template>
  <div class="login_col">
    <div class="login_col_inner">
      <!--   Loader section -->
      <div class="form_loader" :class="{'visible':addResetPassPreloader}">
        <div class="form_loader_bar"></div>
      </div>
      <!--  Logo and top text  -->
      <AccountManagementLogo>
        {{ $t('resetPasswordHeadingMsg') }}
        <template v-slot:custom-heading-text>
          {{ $t('resetPasswordHeadingDescription') }}
        </template>
      </AccountManagementLogo>
      <!--  Reset password form  -->
      <form id="frmResetPassword" @submit.prevent="submitResetPassRequest">
        <div class="alert alert-danger alert-dismissible" v-if="resetEmailError">
          <a href="#" class="alert_close" data-dismiss="alert" aria-label="close">×</a>
          <p>{{ resetEmailErrorText }} </p>
        </div>
        <div class="alert alert-success alert-dismissible" v-if="resetEmailSuccess">
          <a href="#" class="alert_close" data-dismiss="alert" aria-label="close">×</a>
          <p>{{ resetEmailSuccessText }} </p>
        </div>
        <div class="form_group"
             :class="{ 'noplaceholder':confirmResetEmailPlaceholder, 'has_error': $v.resetEmail.$error || resetEmailError }">
          <label class="placeholder" for="resetEmail">Enter your email</label>
          <input type="text" class="form_control" v-model="$v.resetEmail.$model" id="resetEmail"
                 @focus="confirmResetEmailNoPlaceHolder = true" @blur="confirmResetEmailNoPlaceHolder = false" required>
        </div>

        <div class="form_group" :class="{'preloader_on':addResetPassPreloader}">
          <input type="submit" v-model="resetPasswordBtn" :disabled="$v.resetEmail.$error">
          <div class="cray_spinner"></div>
        </div>
      </form>
      <!--   Footer links and buttons -->
      <PageFooterCommon :show-back-to-login-link="true" :show-register-link="true"></PageFooterCommon>
    </div>
  </div>
</template>

<script>
import AccountManagementLogo from "@/components/Logo/AccountManagement/AccountManagementLogo";
import PageFooterCommon from "@/components/AccountManagement/PageFooterCommon";

//import validations
import {required, email, minLength, maxLength} from 'vuelidate/lib/validators'

export default {
  name: "ResetPassword",
  props: ['redirect'],
  components: {
    AccountManagementLogo,
    PageFooterCommon,
  },
  data() {
    return {
      resetEmail: "",
      resetPasswordBtn: "Reset Password",
      confirmResetEmailNoPlaceHolder: false,
      addResetPassPreloader: false,
      resetEmailSuccessText: '',
      resetEmailErrorText: '',
      resetEmailError: false,
      resetEmailSuccess: false,
    }
  },
  computed: {
    confirmResetEmailPlaceholder: function () {
      if (this.confirmResetEmailNoPlaceHolder || this.resetEmail.length > 0) {
        return 'noplaceholder'
      }
      return '';
    }
  },
  methods: {
    removePlaceHolder: function () {
      this.noPlaceHolder = true
    },
    focusInput: function () {
      this.$refs.resetEmail.focus();
    },
    submitResetPassRequest() {
      this.addResetPassPreloader = true;
      let self = this;

      //push the country to the headers
      let updatedHeaders = {
        'locale': this.$language,
      }
      //get the form data
      let dataToSubmit = {
        'email': this.resetEmail,
        'redirect': this.redirect ? this.redirect : this.$defaultRedirect,
      }

      //submit for request
      this.$axios.post(`${this.$authApi}/users/request-password-reset`, dataToSubmit, {
        headers: updatedHeaders
      }).then(function (response) {
        if (response.status === 200) {
          self.addResetPassPreloader = false;
          let data = response.data;
          if (data.status) {
            self.resetEmailSuccess = true
            self.resetEmailSuccessText = data.message
            self.resetEmail =""
            self.resetEmailError = false
            self.resetEmailErrorText=""
          } else {
            self.resetEmailSuccess = false
            self.resetEmailSuccessText = ""
            self.resetEmailError = true
            self.resetEmailErrorText = data.message
          }
        }
      });
    },
  },
  validations: {
    resetEmail: {
      required,
      email,
      minLength: minLength(3),
      maxLength: maxLength(50),
    }
  }
}
</script>

<style scoped>

</style>
