<template>
  <div>
<!--    <ThirdPartyLoginButtons></ThirdPartyLoginButtons>-->

    <!-- Navigation -->
    <AccountManagementNavigation :showRegisterLink="showRegisterLink" :showLoginLink="showLoginLink" :showResetLink="showResetLink" :showBackToLoginLink="showBackToLoginLink" :redirect="redirect"></AccountManagementNavigation>

    <!-- Terms   -->
    <AccountTerms></AccountTerms>
  </div>
</template>

<script>
/*import ThirdPartyLoginButtons from "@/components/AccountManagement/Login/ThirdPartyLoginButtons";*/
import AccountManagementNavigation from "@/components/AccountManagement/AccountManagementNavigation";
import AccountTerms from "@/components/AccountManagement/AccountTerms";
export default {
  name: "PageFooterCommon",
  props:['showRegisterLink','showLoginLink','showResetLink','showBackToLoginLink','redirect'],
  components:{
    /*ThirdPartyLoginButtons,*/
    AccountManagementNavigation,
    AccountTerms
  }
}
</script>

<style scoped>

</style>
