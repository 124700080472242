<template>
    <login-form :redirect="redirect" :service="service"></login-form>
</template>

<script>
import LoginForm from "@/components/AccountManagement/Login/LoginForm";

export default {
  props: ['redirect','service'],
  name: 'Login',
  components: {
    LoginForm,
  }
}
</script>

<style>

</style>
