import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/AccountManagement/Login";
import Register from "@/views/AccountManagement/Register";
import ResetPassword from "@/views/AccountManagement/ResetPassword";
import Verify from "@/views/AccountManagement/Verify";
import SetNewPassword from "@/views/AccountManagement/SetNewPassword";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    props: route => ({redirect: route.query.redirect, service:route.query.service}),
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    props: route => ({redirect: route.query.redirect, service:route.query.service}),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    props: route => ({redirect: route.query.redirect}),
  },
  {
    path: '/users/set-new-password',
    name: 'SetNewPassword',
    component: SetNewPassword,
    props: route => ({redirect: route.query.redirect, token:route.query.token}),
  },
  {
    path: '/users/verify-account/:token?/',
    name: 'Verify',
    component: Verify,
    props: route => ({token: route.params.token}),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
