<template>
  <div class="login_wrap">
    <LoginImage></LoginImage>
    <router-view></router-view>
  </div>
</template>

<script>
import LoginImage from "@/components/AccountManagement/LoginImage";

export default {
  name: 'App',
  data(){
    return {
     //
    }
  },
  components: {
    LoginImage,
  },
}
</script>

<style>

</style>

