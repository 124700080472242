<template>
  <div class="login_col register_page">
    <div class="login_col_inner">
      <!--  Logo and top text  -->
      <!--   Loader section -->
      <div class="form_loader" :class="{'visible':addSignupPreloader }">
        <div class="form_loader_bar"></div>
      </div>

      <AccountManagementLogo>
      <span v-if="!registrationSuccess">
        {{ $t('registerHeadingMsg') }}
      </span>

        <!--    Show success message  -->
        <template v-if="registrationSuccess" v-slot:success_icon>
          <div class="success_icon"><img :src="successIconImage" alt=""></div>
        </template>
        <span v-if="registrationSuccess">
        {{ $t('registerHeadingSuccessMsg') }}
      </span>
        <template v-if="registrationSuccess" v-slot:custom-heading-text>
          {{ $t('registrationSuccessText') }}
        </template>
      </AccountManagementLogo>

      <!--  Registration Form  -->
      <form v-if="!registrationSuccess" id="frmRegister" @submit.prevent="submitRegistrationForm">
        <div class="alert alert-danger alert-dismissible" v-if="registrationError" id="registrationErrorDiv">
          <a href="#" class="alert_close" data-dismiss="alert" aria-label="close">×</a>
          <p>{{ registrationErrorText }}</p>
        </div>
        <div class="form_group" :class="{'noplaceholder':fullNamePlaceholder, 'has_error': $v.fullName.$error}">
          <label class="placeholder" for="fullName">{{ $t('fullNameLabelText') }}</label>
          <input type="text" class="form_control" v-model.trim="$v.fullName.$model"
                 @focus="fullNameNoPlaceholder = true"
                 @blur="fullNameNoPlaceholder=false"
                 id="fullName" required>
        </div>

        <div class="form_group" :class="{'noplaceholder':emailAddressPlaceholder,'has_error':$v.emailAddress.$error}">
          <label class="placeholder" for="emailAddress">{{ $t('emailAddressLabelText') }}</label>
          <input type="email" class="form_control" v-model="$v.emailAddress.$model"
                 @focus="emailAddressNoPlaceholder = true"
                 @blur="emailAddressNoPlaceholder=false"
                 id="emailAddress" required>
        </div>

        <div class="form_group" :class="{'noplaceholder':phoneNumberPlaceholder,'has_error':$v.phoneNumber.$error}">
          <label class="placeholder" for="phoneNumber">{{ $t('phoneNumberLabelText') }}</label>
          <input type="tel" class="form_control" v-model="$v.phoneNumber.$model"
                 @focus="phoneNumberNoPlaceholder = true"
                 @blur="phoneNumberNoPlaceholder=false"
                 id="phoneNumber" required>
        </div>

        <div class="form_group" :class="{'noplaceholder':passwordPlaceholder, 'has_error':$v.password.$error}">
          <label class="placeholder" for="password">{{ $t('passwordLabelText') }}</label>
          <input type="password" class="form_control" v-model="$v.password.$model"
                 @focus="passwordNoPlaceholder = true; showPasswordHint =true;"
                 @blur="passwordNoPlaceholder=false;showPasswordHint =false;"
                 id="password" required>
          <password v-model="$v.password.$model"
                    :default-class="inputClassName"
                    :strength-meter-only="true"
                    :toggle="true"
                    :secureLength="8"
                    :badge="true"
          />

          <!--Help Text for the password-->
          <div class="password-hint-section" v-if="showPasswordHint">
            <p class="form-text text-muted">Password should contain at least:</p>
            <ul>
              <li :class="has_minimum_length ? 'has_required' : ''">8 characters</li>
              <li :class="has_lowercase ? 'has_required' : ''">One lowercase letter e.g. abcdef</li>
              <li :class="has_uppercase ? 'has_required' : ''">One uppercase letter e.g. ABCDEF</li>
              <li :class="has_number ? 'has_required' : ''">One number e.g. 01234</li>
              <li :class="has_special ? 'has_required' : ''">One special character e.g.  @!%$</li>
            </ul>
          </div>
          <!--Help Text for the password-->
        </div>

        <div class="form_group" :class="{'noplaceholder':confirmPasswordPlaceholder, 'has_error':$v.confirmPassword.$error}">
          <label class="placeholder" for="cofirmPassword">{{ $t('confirmPasswordLabelText') }}</label>
          <input type="password" class="form_control" v-model="$v.confirmPassword.$model"
                 @focus="confirmPasswordNoPlaceholder = true;"
                 @blur="confirmPasswordNoPlaceholder=false;"
                 id="cofirmPassword" required>
        </div>

        <div class="form_group" :class="{'preloader_on':addSignupPreloader}">
          <input type="submit"
                 :disabled="$v.fullName.$error || $v.emailAddress.$error || $v.phoneNumber.$error || $v.password.$error"
                 :value="signupBtn">
          <div class="cray_spinner"></div>
        </div>
      </form>

      <!--   Footer links and buttons -->
      <PageFooterCommon :showLoginLink="true"></PageFooterCommon>
    </div>
  </div>
</template>

<script>
import Password from 'vue-password-strength-meter'
import AccountManagementLogo from "@/components/Logo/AccountManagement/AccountManagementLogo";
import PageFooterCommon from "@/components/AccountManagement/PageFooterCommon";
import successIconImage from "@/assets/images/success_icon.svg";

//Custom validation rules
import {email, numeric, required, minLength, maxLength, helpers, sameAs} from 'vuelidate/lib/validators'

let passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&amp;\\*])(?=.{8,})")
const valid_password = helpers.regex('valid_password', passwordRegex)

export default {
  name: "Register",
  props: ['redirect', 'service'],
  components: {
    AccountManagementLogo,
    PageFooterCommon,
    Password
  },
  mounted() {
    //
  },
  data() {
    return {
      signupBtn: "Sign up",
      password: "",
      confirmPassword: "",
      emailAddress: "",
      phoneNumber: "",
      fullName: "",
      isSubmitted: false,
      fullNameNoPlaceholder: false,
      emailAddressNoPlaceholder: false,
      phoneNumberNoPlaceholder: false,
      passwordNoPlaceholder: false,
      confirmPasswordNoPlaceholder: false,
      showPasswordHint:false,
      language: this.$language,
      country: 'KE',
      registrationSuccess: false,
      registrationError: false,
      registrationErrorText: "",
      inputClassName: "form-control",
      successIconImage: successIconImage,
      addSignupPreloader: false,
      has_minimum_length: false,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
    }
  },
  watch: {
    password() {
      this.has_minimum_length = (this.password.length > 8);
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /[!@#$%^&*)(+=._-]/.test(this.password);
    }
  },
  computed: {
    fullNamePlaceholder() {
      if (this.fullNameNoPlaceholder || this.fullName.length > 0) {
        return true;
      }
      return false;
    },
    emailAddressPlaceholder: function () {
      if (this.emailAddressNoPlaceholder || this.emailAddress.length > 0) {
        return true;
      }
      return false;
    },
    phoneNumberPlaceholder: function () {
      if (this.phoneNumberNoPlaceholder || this.phoneNumber.length > 0) {
        return true;
      }
      return false;
    },
    passwordPlaceholder: function () {
      if (this.passwordNoPlaceholder || this.password.length > 0) {
        return true;
      }
      return false;
    },
    confirmPasswordPlaceholder: function () {
      if (this.confirmPasswordNoPlaceholder || this.confirmPassword.length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    submitRegistrationForm: function () {
      let self = this;

      //show preloader
      this.addSignupPreloader = true
      //push the country to the headers
      let updatedHeaders = {
        'locale': this.$language,
        'country': this.$country,
        'service': this.service ? this.service : this.$serviceCode
      }
      //get the form data
      let dataToSubmit = {
        'user_provider': this.$registrationProvider,
        'name': this.fullName,
        'email': this.emailAddress,
        'mobile_number': this.phoneNumber,
        'password': this.password,
        'service': this.service ? this.service : this.$serviceCode,
        'country': this.country,
        'group': 'CAGUL2HFGRD4W',
        'role': 'CART5TKH9CYQA',
        'redirect': this.redirect ? this.redirect : this.$defaultRedirect
      }
      //push to api
      this.$axios.post(`${this.$authApi}/users/register`, dataToSubmit, {
        headers: updatedHeaders
      }).then(function (response) {
        if (response.status === 200) {
          self.addSignupPreloader = false
          //get the data
          let data = response.data;
          if (data.status) {
            self.registrationSuccess = true;

            //reset the form data
            self.emailAddress = "";
            self.fullName = "";
            self.password = "";

          } else {
            self.registrationErrorText = data.message;
            self.registrationError = true
          }
        }
      });
    },
  },
  validations: {
    emailAddress: {
      required,
      email,
      maxLength: maxLength(50)
    },
    phoneNumber: {
      required,
      numeric,
      maxLength: maxLength(50)
    },
    fullName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(50)
    },
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(50),
      valid_password
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs('password')
    }
  }
}
</script>

<style scoped>
.Password {
  max-width: none;
}

.has_required {
  text-decoration: line-through;
  color: #689868;
}
.password-hint-section ul{
  list-style-position: inside;
}
</style>
