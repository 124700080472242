<template>
  <div class="login_col">
    <div class="login_col_inner">
      <!--   Loader section -->
      <div class="form_loader" :class="{'visible':addNewPassPreloader}">
        <div class="form_loader_bar"></div>
      </div>
      <!--  Logo and top text  -->
      <AccountManagementLogo>
        {{ $t('updatePassHeading') }}
        <template v-slot:custom-heading-text>
          {{ $t('updatePassText') }}
        </template>
      </AccountManagementLogo>
      <!--  Set New Password form  -->
      <form @submit.prevent="submitNewPassRequest" autocomplete="off">
        <div class="alert alert-danger alert-dismissible" v-if="newPassError">
          <a href="#" class="alert_close" data-dismiss="alert" aria-label="close">×</a>
          <p>{{ newPassErrorText }} </p>
        </div>

        <div class="form_group" :class="{'noplaceholder':passwordPlaceholder, 'has_error':$v.password.$error}">
          <label class="placeholder" for="password">{{ $t('passwordLabelText') }}</label>
          <input type="password" class="form_control" v-model="$v.password.$model"
                 @focus="passwordNoPlaceholder = true; showPasswordHint =true;"
                 @blur="passwordNoPlaceholder=false;showPasswordHint =false;"
                 id="password" autocomplete="off" required>
          <password v-model="$v.password.$model"
                    :default-class="inputClassName"
                    :strength-meter-only="true"
                    :toggle="true"
                    :secureLength="8"
                    :badge="true"/>

          <!--Help Text for the password-->
          <div class="password-hint-section" v-if="showPasswordHint">
            <p class="form-text text-muted">Password should contain at least:</p>
            <ul>
              <li :class="has_minimum_length ? 'has_required' : ''">8 characters</li>
              <li :class="has_lowercase ? 'has_required' : ''">One lowercase letter e.g. abcdef</li>
              <li :class="has_uppercase ? 'has_required' : ''">One uppercase letter e.g. ABCDEF</li>
              <li :class="has_number ? 'has_required' : ''">One number e.g. 01234</li>
              <li :class="has_special ? 'has_required' : ''">One special character e.g.  @!%$</li>
            </ul>
          </div>
          <!--Help Text for the password-->
        </div>

        <div class="form_group"
             :class="{'noplaceholder':confirmPassPlaceholder, 'has_error':$v.confirmPassword.$error || password!= confirmPassword}">
          <label class="placeholder" for="confirmPassword">Re-enter new password</label>
          <input type="password" class="form_control" v-model="confirmPassword"
                 autocomplete="off"
                 id="confirmPassword"
                 @focus="confirmPasswordNoPlaceHolder = true" @blur="confirmPasswordNoPlaceHolder = false" required>
        </div>

        <div class="form_group" :class="{'preloader_on':addNewPassPreloader}">
          <input type="submit" v-model="setPasswordBtn"
                 :disabled="$v.password.$error || $v.confirmPassword.$error || password!= confirmPassword ">
          <div class="cray_spinner"></div>
        </div>
      </form>
      <!--   Footer links and buttons -->
      <PageFooterCommon :show-back-to-login-link="true" :show-register-link="true"></PageFooterCommon>
    </div>
  </div>
</template>

<script>
import Password from 'vue-password-strength-meter'
import AccountManagementLogo from "@/components/Logo/AccountManagement/AccountManagementLogo";
import PageFooterCommon from "@/components/AccountManagement/PageFooterCommon";

//import validations
import {required, minLength, maxLength, helpers} from 'vuelidate/lib/validators'
import successIconImage from "@/assets/images/success_icon.svg";

let passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&amp;\\*])(?=.{8,})")
const valid_password = helpers.regex('valid_password', passwordRegex)

export default {
  name: "SetNewPassword",
  props: ['token', 'redirect'],
  components: {
    AccountManagementLogo,
    PageFooterCommon,
    Password
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      setPasswordBtn: "Update Password",
      passwordNoPlaceHolder: false,
      confirmPasswordNoPlaceHolder: false,
      addNewPassPreloader: false,
      newPassError: false,
      newPassErrorText: "",
      signupBtn: "Sign up",
      emailAddress: "",
      phoneNumber: "",
      fullName: "",
      isSubmitted: false,
      fullNameNoPlaceholder: false,
      emailAddressNoPlaceholder: false,
      phoneNumberNoPlaceholder: false,
      passwordNoPlaceholder: false,
      confirmPasswordNoPlaceholder: false,
      showPasswordHint:false,
      language: this.$language,
      country: 'KE',
      registrationSuccess: false,
      registrationError: false,
      registrationErrorText: "",
      inputClassName: "form-control",
      successIconImage: successIconImage,
      addSignupPreloader: false,
      has_minimum_length: false,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
    }
  },
  computed: {
    passwordPlaceholder: function () {
      if (this.passwordNoPlaceHolder || this.password.length > 0) {
        return true
      }
      return false;
    },
    confirmPassPlaceholder: function () {
      if (this.confirmPasswordNoPlaceHolder || this.confirmPassword.length > 0) {
        return true
      }
      return false;
    }
  },
  methods: {
    removePlaceHolder: function () {
      this.noPlaceHolder = true
    },
    focusInput: function () {
      this.$refs.password.focus();
    },
    submitNewPassRequest() {
      this.addNewPassPreloader = true
      let self = this;

      //push the country to the headers
      let updatedHeaders = {
        'locale': this.$language,
      }
      //get the form data
      let _redirect = this.$route.query.redirect ? this.$route.query.redirect : this.$defaultRedirect
      let _new_password_token = this.$route.query.new_password_token ? this.$route.query.new_password_token : ""

      let dataToSubmit = {
        'token': this.token ? this.token : _new_password_token,
        'password': this.password,
        'password_confirmation': this.confirmPassword,
        'redirect': this.redirect ? this.redirect : _redirect,
        'new_password': this.$route.query.new_password ? this.$route.query.new_password : 0
      }

      //submit for request
      this.$axios.post(`${this.$authApi}/users/update-password`, dataToSubmit, {
        headers: updatedHeaders
      }).then(function (response) {
        if (response.status === 200) {
          self.addNewPassPreloader = false;
          let data = response.data;
          if (data.status) {
            self.newPassError = false;
            self.newPassErrorText = ''
            //redirect to login page
            self.$router.push({ path: '/', query: { redirect: self.redirect } })
          }else{
            self.newPassError = true;
            self.newPassErrorText = data.message
          }
        }
      });
    }
  },
  validations: {
    password: {
      valid_password,
      required,
      minLength: minLength(8),
      maxLength: maxLength(50),
    },
    confirmPassword: {
      valid_password,
      required,
      minLength: minLength(8),
      maxLength: maxLength(50),
    }
  },
  watch: {
    password() {
      this.has_minimum_length = (this.password.length > 8);
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /[!@#$%^&*)(+=._-]/.test(this.password);
    }
  },
}
</script>

<style scoped>
.Password {
  max-width: none;
}

.has_required {
  text-decoration: line-through;
  color: #689868;
}
.password-hint-section ul{
  list-style-position: inside;
}
</style>