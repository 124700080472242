<template>
  <ul class="fine_print">
    <li><a href="https://craydel.com/privacy-policy" target="_blank">{{ $t('privacyPolicyLinkText')}}</a></li>
    <li><a href="https://craydel.com/terms-of-use" target="_blank">{{ $t('userNoticeLinkText')}}</a></li>
  </ul>
</template>

<script>
export default {
  name: "AccountTerms"
}
</script>

<style scoped>

</style>
