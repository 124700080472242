<template>
  <div class="logo_login">
    <img class="login_logo_img" src="https://craydel.fra1.cdn.digitaloceanspaces.com/logo/craydel.svg" alt="Craydel">
      <slot name="success_icon"></slot>
    <h4><slot></slot></h4>
    <p><slot name="custom-heading-text"></slot></p>
  </div>
</template>

<script>
export default {
  name: "AccountManagementLogo",
  data(){
    return{
    }
  }
}
</script>

<style scoped>

</style>
