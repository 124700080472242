import Vue from 'vue'
import App from './App.vue'

import i18n from "@/plugins/i18n"
import router from './router'
import axios from "axios";
import Vuelidate from "vuelidate/src";


Vue.config.productionTip = false;
Vue.use(Vuelidate)


//global variables
Vue.prototype.$axios = axios;
Vue.prototype.$authApi = process.env.VUE_APP_ACCOUNTS_API_ENDPOINT
Vue.prototype.$ipInfo = 'https://ipwhois.app/json/'
Vue.prototype.$defaultRedirect = 'https://craydel.com/'
Vue.prototype.$serviceCode = 'SRVBC11P55U0Y'
Vue.prototype.$country = localStorage.getItem('country')
Vue.prototype.$registrationProvider = 'custom'
Vue.prototype.$facebookRegistrationProvider = 'facebook'
Vue.prototype.$googleRegistrationProvider = 'google'
//set the headers
Vue.prototype.$headers = {
    'locale': navigator.language.split('-')[0],
    'service': 'SRVBC11P55U0Y',
}
Vue.prototype.$language = navigator.language.split('-')[0];

//password regex
Vue.prototype.$passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&amp;\\*])(?=.{8,})")


new Vue({
    i18n,
    router,
    data(){
        return {
            country:''
        }
    },
    beforeCreate() {
        //check if the counrty is already set for this browser
        let country = localStorage.getItem('country');
        if(country){
            //dont do anything
        }else{
            const url = 'https://ipapi.co/json/';
            axios.get(url).then(response => {
                if(response.data.country_code) {
                    localStorage.setItem('country', response.data.country_code)
                }else{
                    localStorage.setItem('country', 'KE')
                }
            });
        }
    },
    render: h => h(App)
}).$mount('#app')
