<template>
  <div class="login_col" v-if="!isLoading">
    <div class="login_col_inner">
      <!--  Logo and top text  -->
      <AccountManagementLogo>
        <template v-slot:success_icon v-if="isVerified">
          <div class="success_icon"><img :src="successIconImage" alt=""></div>
        </template>
        <span v-if="isVerified">
        {{ $t('verifyAccountHeading') }}
      </span>
        <span v-else>
        {{ $t('verifyAccountErrorHeading') }}
      </span>

        <template v-slot:custom-heading-text v-if="isVerified">
          {{ $t('verifyAccountText') }}
        </template>

        <template v-slot:custom-heading-text v-else>
          {{ verificationErrroMsg }}
        </template>
      </AccountManagementLogo>
      <router-link :to="`/?redirect=${redirectUrl}`" class="login_link">Login</router-link>
    </div>
  </div>
</template>

<script>
import AccountManagementLogo from "../../components/Logo/AccountManagement/AccountManagementLogo";
import successIconImage from "@/assets/images/success_icon.svg";

export default {
  name: "Verify",
  props: ['token'],
  components: {
    AccountManagementLogo: AccountManagementLogo
  },
  mounted() {
    let self = this;
    //get the country
    const url = this.$ipInfo;
    this.$axios.get(url).then(response => {
      this.country = response.data.country_code
    });

    //create the header
    let updatedHeaders = {
      'locale': this.$language,
      'country': this.country,
      'service': this.$serviceCode
    }
    //get the form data
    let dataToSubmit = {
      'code': this.token
    }


    //get verify the token from the api and see if it exists
    //push to api
    this.$axios.post(`${this.$authApi}/users/verify-account`, dataToSubmit, {
      headers: updatedHeaders
    }).then(function (response) {
      if (response.status === 200) {
        let data = response.data;
        if (data.status) {
          if(!data.data.has_set_password){
            self.$router.push({
              path: '/users/set-new-password',
              query: {
                new_password: '1',
                redirect: data.data.redirect_url,
                new_password_token: data.data.new_password_token
              }
            })
          }else {
            self.isLoading=false;
            self.isVerified = true;
            self.redirectUrl = data.data.redirect_url ? data.data.redirect_url : ""
          }
        } else {
          self.isLoading=false;
          self.isVerified = false;
          self.verificationErrroMsg = data.message
        }
      }
    });
  },
  data() {
    return {
      successIconImage: successIconImage,
      isVerified: false,
      isLoading:true,
      verificationErrroMsg: "",
      country: "",
      redirectUrl: "",
    }
  },
}
</script>

<style scoped>

</style>
