<!--This is a component to manage the left side of the login page-->
<template>
  <div class="login_left_panel"></div>
</template>

<script>
export default {
  name: "LoginImage"
}
</script>

<style scoped>

</style>
