<template>
  <ul class="utility_login_nav">
    <li v-if="showLoginLink"><router-link to="/" >{{ $t('loginLinkText')}}</router-link></li>
    <li v-if="showResetLink"><router-link to="/reset-password">{{ $t('forgotPasswordLinkText')}}</router-link></li>
    <li v-if="showBackToLoginLink"><router-link to="/">{{ $t('backToLoginLinkText')}}</router-link></li>
    <li v-if="showRegisterLink"><router-link :to="`/register?redirect=${redirect}`">{{ $t('createAccountLinkText')}}</router-link></li>
  </ul>
</template>

<script>
export default {
  props:{
    showRegisterLink : {
      type:Boolean,
      default:false
    },
    showLoginLink : {
      type:Boolean,
      default:false
    },
    showResetLink : {
      type:Boolean,
      default:false
    },
    showBackToLoginLink : {
      type:Boolean,
      default:false
    },
    redirect:{
      default:''
    },
  },
  name: "AccountManagementNavigation",
  computed:{

  }
}
</script>

<style scoped>

</style>
